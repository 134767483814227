import React from 'react'

export const PrivacyPolicy = () => {
    return (
        <div class="container py-5 mt-5">
            <h1>Políticas de Privacidad</h1>

            <p>Última Actualización: November 06, 2021</p>

            <p>
                Esta Política de privacidad describe Nuestras políticas y procedimientos sobre la recopilación,
                el uso y la divulgación de Su información cuando usa el Servicio y le informa sobre Sus derechos
                de privacidad y cómo la ley lo protege.
            </p>

            <p>
                Usamos sus datos personales para proporcionar y mejorar el Servicio. Al utilizar el Servicio,
                acepta la recopilación y el uso de información de acuerdo con esta Política de privacidad.
            </p>


            <h1>Interpretación and Definiciones</h1>

            <h2>Interpretación</h2>

            <p>
                Las palabras cuya letra inicial está en mayúscula tienen significados definidos en las siguientes
                condiciones. Las siguientes definiciones tendrán el mismo significado independientemente de que
                aparezcan en singular o en plural.
            </p>

            <h2>Definiciones</h2>

            <p>A los efectos de esta Política de privacidad:</p>

            <ul>
                <li>
                    <p>
                        <strong>Cuenta</strong> significa una cuenta única creada para que usted acceda a nuestro
                        Servicio o partes de nuestro Servicio.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Compañía </strong> (referida como "la Compañía", "Nosotros", "Nos" o "Nuestro" en
                        este Acuerdo) se refiere a MotoElectrics, Calle Aquiles Serdan 179 Pte, Zona Centro. Durango, Dgo ..
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Las cookies </strong> son pequeños archivos que un sitio web coloca en su computadora,
                        dispositivo móvil o cualquier otro dispositivo, que contienen los detalles de su historial de
                        navegación en ese sitio web, entre sus muchos usos.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>El país</strong> se refiere a:  Mexico
                    </p>
                </li>
                <li>
                    <p>
                        <strong> Dispositivo </strong> se refiere a cualquier dispositivo que pueda acceder al Servicio,
                        como una computadora, un teléfono celular o una tableta digital.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Datos personales </strong> es cualquier información relacionada con una persona
                        identificada o identificable.
                    </p>
                </li>
                <li>
                    <p><strong>Servicio</strong> se refiere a la página web.</p>
                </li>
                <li>
                    <p>
                        <strong>Proveedor de servicios </strong> significa cualquier persona física o jurídica que procesa
                        los datos en nombre de la Compañía. Se refiere a empresas de terceros o personas empleadas por la Compañía
                        para facilitar el Servicio, para proporcionar el Servicio en nombre de la Compañía, para realizar servicios
                        relacionados con el Servicio o para ayudar a la Compañía a analizar cómo se utiliza el Servicio.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Datos de uso </strong> se refiere a los datos recopilados automáticamente, ya sea generados por el
                        uso del Servicio o por la propia infraestructura del Servicio (por ejemplo, la duración de una visita a la página).
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Sitio web </strong> se refiere a MotoElectrics, accesible desde
                        <a href="motoelectrics.com" rel="external nofollow noopener" target="_blank"> motoelectrics.com </a>
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Usted </strong> se refiere a la persona que accede o utiliza el Servicio, o la empresa u otra
                        entidad legal en nombre de la cual dicha persona accede o utiliza el Servicio, según corresponda.
                    </p>
                </li>
            </ul>

            <h1>Recopilación y uso de sus datos personales</h1>

            <h2>Tipos de datos recopilados</h2>

            <h3>Datos Personales</h3>

            <p>
                Al usar Nuestro Servicio, podemos pedirle que nos proporcione cierta información de identificación personal que
                pueda usarse para contactarlo o identificarlo. La información de identificación personal puede incluir, pero
                no se limita a:
            </p>
            <ul>
                <li>
                    <p>Correo Electrónico</p>
                </li>
                <li>
                    <p>Nombres y Apellidos</p>
                </li>
                <li>
                    <p>Número de teléfono</p>
                </li>
                <li>
                    <p>Dirección, estado, provincia, código postal / código postal, ciudad</p>
                </li>
                <li>
                    <p>Uso de información</p>
                </li>
            </ul>

            <h3>Uso de información</h3>

            <p>Los datos de uso se recopilan automáticamente cuando se utiliza el servicio.</p>

            <p>
                Los datos de uso pueden incluir información como la dirección del Protocolo de Internet de su dispositivo
                (por ejemplo, la dirección IP), el tipo de navegador, la versión del navegador, las páginas de nuestro
                Servicio que visita, la hora y fecha de su visita, el tiempo que pasó en esas páginas, el dispositivo
                único identificadores y otros datos de diagnóstico.
            </p>

            <p>
                Cuando accede al Servicio a través de un dispositivo móvil, podemos recopilar cierta información automáticamente,
                que incluye, entre otros, el tipo de dispositivo móvil que utiliza, la identificación única de su dispositivo móvil,
                la dirección IP de su dispositivo móvil, su dispositivo móvil sistema operativo, el tipo de navegador de Internet
                móvil que utiliza, identificadores de dispositivo únicos y otros datos de diagnóstico.
            </p>

            <p>
                También podemos recopilar información que su navegador envía cada vez que visita nuestro Servicio o cuando accede al
                Servicio a través de un dispositivo móvil.
            </p>

            <h3>Tecnologías de seguimiento y cookies</h3>

            <p>
                Usamos cookies y tecnologías de seguimiento similares para rastrear la actividad en nuestro servicio y almacenar cierta
                información. Las tecnologías de seguimiento utilizadas son balizas, etiquetas y scripts para recopilar y rastrear información
                y para mejorar y analizar Nuestro Servicio. Las tecnologías que utilizamos pueden incluir:
            </p>

            <ul>
                <li>
                    <strong>Cookies o cookies del navegador. </strong> Una cookie es un pequeño archivo que se coloca en su dispositivo.
                    Puede indicar a su navegador que rechace todas las cookies o que indique cuándo se envía una cookie. Sin embargo, si
                    no acepta las cookies, es posible que no pueda utilizar algunas partes de nuestro Servicio. A menos que haya ajustado
                    la configuración de su navegador para que rechace las cookies, nuestro servicio puede utilizar cookies.
                </li>
            </ul>

            <p>
                Las cookies pueden ser & quot; Persistentes &quot; o &quot; Sesión &quot; Galletas. Las cookies persistentes permanecen en su computadora personal o dispositivo móvil cuando se desconecta, mientras que las cookies de sesión se eliminan tan pronto como cierra su navegador web.
            </p>

            <h2>Uso de sus datos personales</h2>

            <p>La Compañía puede utilizar los Datos Personales para los siguientes propósitos:</p>

            <ul>
                <li>
                    <p><strong>Para proporcionar y mantener nuestro Servicio </strong>, incluso para controlar el uso de nuestro Servicio.</p>
                </li>
                <li>
                    <p>
                        <strong> Para otros fines </strong>: podemos utilizar su información para otros fines, como análisis de datos,
                        identificación de tendencias de uso, determinación de la efectividad de nuestras campañas promocionales y para
                        evaluar y mejorar nuestro Servicio, productos, servicios, marketing y tu experiencia.
                    </p>
                </li>
            </ul>

            <h2> Conservación de sus datos personales </h2>

            <p>
                La Compañía retendrá sus datos personales solo durante el tiempo que sea necesario para los fines establecidos
                en esta Política de privacidad. Retendremos y usaremos sus datos personales en la medida necesaria para
                cumplir con nuestras obligaciones legales (por ejemplo, si estamos obligados a retener sus datos para
                cumplir con las leyes aplicables), resolver disputas y hacer cumplir nuestros acuerdos y políticas legales.
            </p>

            <p>
                La Compañía también retendrá los Datos de uso con fines de análisis interno. Los datos de uso generalmente
                se conservan durante un período de tiempo más corto, excepto cuando estos datos se utilizan para fortalecer
                la seguridad o mejorar la funcionalidad de nuestro servicio, o estamos legalmente obligados a conservar
                estos datos durante períodos de tiempo más largos.
            </p>

            <h2>Transferencia de sus datos personales</h2>

            <p>
                Su información, incluidos los Datos personales, se procesa en las oficinas operativas de la Compañía
                y en cualquier otro lugar donde se encuentren las partes involucradas en el procesamiento. Significa
                que esta información puede transferirse y mantenerse en computadoras ubicadas fuera de su estado,
                provincia, país u otra jurisdicción gubernamental donde las leyes de protección de datos pueden
                diferir de las de su jurisdicción.
            </p>

            <p>
                Su consentimiento a esta Política de privacidad seguido de Su envío de dicha información representa
                Su aceptación de esa transferencia.
            </p>

            <p>
                La Compañía tomará todas las medidas razonablemente necesarias para garantizar que sus datos sean
                tratados de forma segura y de acuerdo con esta Política de privacidad y no se realizará ninguna
                transferencia de sus datos personales a una organización o país a menos que existan controles adecuados
                establecidos, incluyendo la seguridad de sus datos y otra información personal.
            </p>

            <h2> Divulgación de sus datos personales </h2>

            <h3> Transacciones comerciales </h3>

            <p>
                Si la Compañía está involucrada en una fusión, adquisición o venta de activos, sus datos personales
                pueden ser transferidos. Le enviaremos un aviso antes de que sus datos personales se transfieran y
                estén sujetos a una política de privacidad diferente.
            </p>

            <h3> Aplicación de la ley </h3>

            <p>
                En determinadas circunstancias, es posible que la Compañía deba divulgar sus datos personales si así
                lo exige la ley o en respuesta a solicitudes válidas de las autoridades públicas (por ejemplo, un
                tribunal o una agencia gubernamental).
            </p>

            <h3> Otros requisitos legales </h3>

            <p>
                La Compañía puede divulgar sus datos personales de buena fe cuando considere que dicha acción es
                necesaria para:
            </p>

            <ul>
                <li>
                    Cumplir con una obligación legal
                </li>
                <li>
                    Proteger y defender los derechos o propiedad de la Empresa.
                </li>
                <li>
                    Prevenir o investigar posibles irregularidades en relación con el Servicio.
                </li>
                <li>
                    Proteger la seguridad personal de los Usuarios del Servicio o del público.
                </li>
                <li>
                    Protéjase contra la responsabilidad legal
                </li>
            </ul>

            <h2> Seguridad de sus datos personales </h2>

            <p> La seguridad de sus datos personales es importante para nosotros, pero recuerde que ningún método de
                transmisión a través de Internet o método de almacenamiento electrónico es 100% seguro. Si bien nos
                esforzamos por utilizar medios comercialmente aceptables para proteger sus datos personales, no
                podemos garantizar su seguridad absoluta. </p>

            <h1> Privacidad de los niños </h1>

            <p>
                Nuestro Servicio no se dirige a ninguna persona menor de 13 años. No recopilamos a sabiendas
                información de identificación personal de ninguna persona menor de 13 años. Si usted es un padre
                o tutor y sabe que su hijo nos ha proporcionado información personal Datos, contáctenos. Si nos
                damos cuenta de que hemos recopilado Datos personales de cualquier persona menor de 13 años sin
                la verificación del consentimiento de los padres, tomamos medidas para eliminar esa información
                de Nuestros servidores.
            </p>

            <p>
                Si necesitamos depender del consentimiento como base legal para procesar su información y su país
                requiere el consentimiento de uno de los padres, es posible que necesitemos el consentimiento de
                sus padres antes de recopilar y utilizar esa información.
            </p>

            <h1> Vínculos a otros sitios web </h1>

            <p>
                Nuestro Servicio puede contener enlaces a otros sitios web que no son operados por Nosotros. Si hace
                clic en el enlace de un tercero, será dirigido al sitio de ese tercero. Le recomendamos encarecidamente
                que revise la Política de privacidad de cada sitio que visite.
            </p>

            <p>
                No tenemos control ni asumimos ninguna responsabilidad por el contenido, las políticas de privacidad
                o las prácticas de sitios o servicios de terceros.
            </p>

            <h1> Cambios en esta Política de privacidad </h1>

            <p>
                Podemos actualizar nuestra Política de privacidad de vez en cuando. Le notificaremos de cualquier
                cambio publicando la nueva Política de privacidad en esta página.
            </p>

            <p>
                Los cambios de esta política de privacidad, podrá sufrir cambios sin previo aviso.
            </p>

            <p>
                Le recomendamos que revise esta Política de privacidad periódicamente para ver si hay cambios. Los
                cambios a esta Política de privacidad entran en vigencia cuando se publican en esta página.
            </p>


            <h1>Contáctanos</h1>

            <p>Si tiene alguna pregunta sobre esta Política de privacidad, puede contactarnos:</p>

            <ul>
                <li>Por correo: contacto@motoelectrics.com</li>
            </ul>
        </div>
    )
}
