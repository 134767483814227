import { AppRouter } from "./router/AppRouter";

function App() {
    return (
		<>
			<AppRouter />
		</>
	);
}

export default App;
