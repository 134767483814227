import React from 'react'
import { useState } from 'react';
import { BatteryComponent } from './BatteryComponent'
import ChooseBattery from './ChooseBattery';
import { MoreInfoItem } from './MoreInfoItem'

export const BatterySelection = ({ batteries, bikeOptions, setBikeOptions }) => {

    const { battery } = bikeOptions;

    const [openInfoModal, setOpenInfoModal] = useState(false);

    React.useEffect(() => {
        if (batteries.length > 0) {
            setBatterySelected(batteries[0].id);
        }
    }, []);    

    const setBatterySelected = (battery) => {
        setBikeOptions(state => ({
            ...state,
            battery: battery,
        }))
    }

    return (
        <div className = "mb-5">
            { batteries.length > 0 && (
                <>
                    <p>Selecciona el tipo de batería que quieres.</p>
                    { batteries.map(batteryItem => (
                        <BatteryComponent 
                            key = { batteryItem.id }
                            active = { batteryItem.id === battery }
                            options = { batteryItem }
                            onClick = {() => {
                                setBatterySelected(batteryItem.id);
                            }}
                        />      
                    )) }
                    <MoreInfoItem
                        title = '¿Cómo seleccionar tu batería?'
                        description = 'Conoce cada una de nuestras baterías para que selecciones la mejor opción para ti.'
                        onOpenModal = { () => setOpenInfoModal(true) }
                    />
                    <ChooseBattery 
                        show = { openInfoModal }
                        handleClose = { () => setOpenInfoModal(false) }
                    />
                </>
            )}
        </div>
    )
}
