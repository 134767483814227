import { Button, Modal } from "react-bootstrap";


const ModalContent = () => {

    // TODO: Vaciar el contenido del modal aqui, y renderizar allá.

    return (
        <>
            <p>
                Cada vez más personas apuestan por la moto eléctrica para moverse por la ciudad, y es que las 
                ventajas de este scooter hacen que sean una alternativa ideal. Uno de los puntos más llamativos es 
                la batería de la moto eléctrica, ya que su gran capacidad y potencia te permiten disfrutar de una 
                inigualable autonomía. ¡Ven a Motoelectrics y conoce todos los detalles!
            </p>
            <div>
                <h4>Autonomía de las motos eléctricas</h4>
                <p>
                    Las motos eléctricas están compuestas por un motor eléctrico y una batería de gran potencia con 
                    la que podrás recorrer cualquier rincón de la ciudad sin ningún problema. Estas baterías pueden 
                    ser opcional de ácido-plomo o de ion litio, las cuales se caracterizan por un mejor rendimiento 
                    y potencia.
                </p>
                <p>
                    Además, ofrecen la ventaja de cargarse en menos tiempo y tardar más tiempo en descargarse, así 
                    como no tienen efecto memoria y prácticamente no pierden carga cuando no se usan. Esto supone 
                    una gran diferencia en la respuesta de nuestra moto.
                </p>
                <p>
                    A la hora de elegir un vehículo eléctrico para moverte, la principal preocupación que surge a los 
                    conductores es su autonomía. ¡Y aquí tenemos para ti algunas recomendaciones a la hora de elegir 
                    la capacidad de batería que más te convenga!
                </p>
                <ul>
                    <li>
                        <p>
                            Has una medición aproximada de los km que recorres en tu día a día; de la casa a la 
                            escuela o trabajo, tu salida a la hora de lunch y regreso a tu casa donde definirás 
                            tu punto de carga.
                        </p>
                    </li>
                    <li>
                        <p>
                            Toma en cuenta por la tarde tus salidas a visitar a un amigo o familia, si vas a trasladas 
                            a alguna otra persona en tu traslado, recuerda que el consumo de energía varía en función 
                            a la demanda que tu le exige a la unidad, a mayor carga mayor consumo de energía.
                        </p>
                    </li>
                    <li>
                        <p>
                            Una recomendación importante es tener en cuenta las horas que tarda en cargar de 0-100% tu 
                            batería, ronda entre 6-8 horas, por lo que tendrás que considerar tener conectada tu moto 
                            mientras tu descansas por la noche. El cargador o sistema de carga en general de la moto 
                            detectara automáticamente cuando la batería esté a un 100% de carga y dejara de inyectar 
                            energía para evitar daño o preservar la vida útil de tu batería.
                        </p>
                    </li>
                    <li>
                        <p>
                            Una recomendación para alargar la vida útil de tu batería es procurar no descargar tu batería 
                            hasta un 0%, mantener con unos niveles considerables en tu batería la mayoría del tiempo será 
                            punto clave para alargar la vida útil de tu batería.    
                        </p>
                    </li>
                </ul>
            </div>
        </>
    )
}

const ChooseBattery = ({ show, handleClose }) => {
    return (
        <div
            className="modal show"
        >
                <Modal 
                    show = { show } onHide = { handleClose } centered
                >
                    <div className = "detail_choose_battery_modal">
                        <Modal.Header closeButton>
                            <Modal.Title><h3>¿Cuántos kilómetros dura la batería de una moto eléctrica?</h3></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <ModalContent />
                        </Modal.Body>
                        <Modal.Footer>
                            {/* <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button> */}
                            <Button variant="primary" onClick={handleClose}>
                                Entendido
                            </Button>
                        </Modal.Footer>
                    </div>
                </Modal>
        </div>
    )
}

export default ChooseBattery;
