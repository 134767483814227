import React from 'react';

import placeholder from '../../assets/reviews/logo-negro.jpg';

export const ReviewCard = ({ username, review, profilePhoto }) => {
    return (
        <div className = "col">
            <div className = "card">
                <img src = { profilePhoto ? profilePhoto : placeholder } className = "card-img-top" alt = "Foto Perfil"/>
                    <div className = "card-body">
                        <p className = "card-text">{ review }</p>
                        <h6 className = "card-title">- { username }</h6>
                    </div>
            </div>
        </div>
    )
}
