import React from 'react'

import background from '../assets/covers/black-racing.jpeg'
import { PrimaryButton } from './UI/PrimaryButton'

export const CatalogLink = () => {
    return (
        <div
            className = ' catalog_container d-flex flex-column justify-content-center '
            style = {{
                background: `url(${background})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: false
            }}
        >
            <p
                className = ' display-4 pb-4 '
            >CONOCE NUESTRO CATÁLOGO</p>
            <PrimaryButton
                text = 'Descargar Catálogo'
                link = 'https://motoelectrics.com/assets/files/MotoElectrics-Catalogo.pdf'
                external = { true }
            />
        </div>
    )
}
