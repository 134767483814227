import { useNavigate } from "react-router-dom";

export const ProductLink = ({ image, link, name, price, comingSoon = false }) => {

    const navigate = useNavigate();

    const handleClick = () => {
        if(!comingSoon){
            navigate(`/producto/${ link }`)
        }
    }

    return (
        <div 
            className = ' productLink_container col-xs-12 col-sm-6 col-md-4 col-lg-4 '
            onClick = { handleClick }
        >
            { !comingSoon ? (
                <div className = ' productLink_white pt-2 pb-4  '>
                    <p className = ' productLink_name mt-3 '>{ name }</p>
                    <p className = ' productLink_price mb-3 '>Desde ${ price }</p>
                    <img 
                        alt = { name }
                        className = ' productLink_image '
                        src = { image }
                    />
                </div>
            ) : 
            (
                <div className = ' productLink_comingSoon-container pt-2 pb-4 '>
                    <p className = ' productLink_name mt-3 '>{ name }</p>
                    <div className = ' productLink_comingSoon '><span className = ' productLink_comingSoon-content '>¡Proximamente!</span></div>
                    <img
                        alt = { name }
                        className = ' productLink_image mt-4 '
                        src = { image }
                    />
                </div>
            )
            }
        </div>
    )
}
