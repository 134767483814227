import React from 'react'

import logo from '../assets/logo.png'

export const Footer = () => {

    return (
        <div className = 'footer pt-5 pb-3'>
            <div className = 'container'>
                <div className = 'row'>
                    <div className = 'col-xs-12 col-sm-4 col-md-3'>
                        <img
                            alt = 'logo'
                            className = 'logo'
                            src = { logo }
                        />
                    </div>
                    <div className = 'col-xs-12 col-sm-4 col-md-3 mt-4 mt-md-0'>
                        <p className = 'footer_title'>Enlaces</p>
                        <ul className = 'footer_list'>
                            <li>
                                <a href = '/#sports'>Modelos</a>
                            </li>
                            <li>
                                <a href = 'https://motoelectrics.com/tienda/categoria-producto/accesorios/'>Accesorios</a>
                            </li>
                            <li>
                                <a href = 'https://motoelectrics.com/tienda'>Tienda</a>
                            </li>
                            <li>
                                <a href = '/testimonios'>Testimonios</a>
                            </li>
                        </ul>
                    </div>
                    <div className = 'col-xs-12 col-sm-4 col-md-3 mt-4 mt-md-0'>
                        <p className = 'footer_title'>Legal</p>
                        <ul className = 'footer_list'>
                            <li>
                                <a href = '/terminos-condiciones'>Términos y Condiciones</a>
                            </li>
                            <li>
                                <a href = '/politicas-privacidad'>Políticas de Privacidad</a>
                            </li>
                        </ul>
                    </div>
                    <div className = 'col-xs-12 col-sm-4 col-md-3 mt-4 mt-md-0'>
                        <p className = 'footer_title'>Contáctanos</p>
                        <ul className = 'footer_list'>
                            <li>
                                <a href = '/contacto'>Contacto</a>
                            </li>
                            <li>
                                <a href = 'https://wa.link/om8i8d'>WhatsApp</a>
                            </li>
                            <li>
                                <a href = 'https://www.facebook.com/motoelectrics.mx'>Facebook</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className = 'text-center pt-4 copyright'>
                <p>
                    Copyright 2022 © Todos los derechos reservados - Desarrollado por 
                    <a href = 'https://avluetsoftware.com'> Avluet Software</a>
                </p>
            </div>
        </div>
    )
}
