import React from 'react'
import { PrimaryButton } from '../UI/PrimaryButton'
import accessoryPhoto from '../../assets/home/accessoryplaceholder.jpg'

export const HomeAccessories = () => {
    return (
        <div className = ' d-block d-md-flex home_accessories mb-4 pb-md-0 text-center text-md-start'>
            <div className = ' co-12 col-md-5 d-flex flex-column justify-content-center '>
                <p className = ' section-title '>CONOCE NUESTROS ACCESORIOS</p>
                <p className = ' text_subtitle '>
                    Encuentra los accesorios que necesites de pies a cabeza para manejar tus motos.
                </p>
                <p className = ' mb-5 '>
                    Nosotros tenemos los accesorios ideales para que te sientas 
                    cómodo y protegido. Checa en nuestra tienda toda nuestra línea 
                    de accesorios y recíbelo en la puerta de tu casa.
                </p>
                <div className = 'w-100 d-flex justify-content-center justify-content-md-start'>
                    <PrimaryButton 
                        link = 'https://motoelectrics.com/tienda/categoria-producto/accesorios/'
                        external = {true}
                        text = 'COMPRAR'
                    />
                </div>
            </div>
            <div className = ' col-12 col-md-7 p-4 '>
                <img src = { accessoryPhoto } alt = 'Accesorios Placeholder' className = ' w-100 '/>
            </div>
        </div>
    )
}
