import React from 'react'

export const Colors = ({ colors }) => {
  return (
    <div className = 'mb-4'>
        <div className = 'd-block'>
            <p className = 'mb-2 text_bold'>Colores Disponibles</p>
        </div>
        <div className = 'd-flex'>
            { colors.length > 0 &&
                colors.map( color => (
                    <div 
                        key = { color }
                        className = 'bike_color-container me-2'
                    >
                        <div 
                            className = ' detail_specs-color bike_color-item'
                            style = {{
                                backgroundColor: `${ color }`,
                            }}
                        />
                    </div>
                ))
            }
        </div>
    </div>
  )
}
