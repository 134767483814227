import React from 'react';
import { formatMoney } from '../../../utils/formatMoney';

export const BatteryComponent = ({ active, onClick, options }) => {

    const { description, name, difference } = options;

    return (
        <div className = {`bike_battery-container ${ active ? 'bike_active' : ''}`} onClick = { onClick }>
            <p className='bike_battery-name'>{ name }</p>
            <p className='bike_battery-description'>{ description }</p>
            <p className='bike_battery-price'>+ { formatMoney(difference) }</p>
        </div> 
    );
}