import React, { useState } from 'react'
import { Colors } from './Colors';

export const BikeSpecs = ({ product }) => {
    const [ moreSpecs, setMoreSpecs ] = useState(false);
    
    const { brand, colors, name, specs } = product;

    return (
        <div>
            <p className = ' h3 '>ESPECIFICACIONES</p>
            <p className = 'bike_name'>{ name }</p>
            <div className = ' detail_specs-list-container '>
                <p className = ' mb-2 '>Marca { brand }</p>
                { specs.length > 0 && 
                    specs.slice(0, 4).map(spec => (
                        <p key = { spec } className = ' mb-2 '>{ spec }</p>
                    ))
                }
                <div className = ' mt-3 '>
                    <Colors colors = { colors } />
                </div>
                { !!moreSpecs && 
                    <div>
                        <p className = ' detail_specs-more mt-3 mb-2 '>Más Especificaciones</p>
                        {specs.slice(4, specs.length).map(spec => (
                            <p key = { spec } className = ' mb-2 '>{ spec }</p>
                        ))}
                    </div>                            
                }
            
            </div>
            {(specs.length > 4 && !moreSpecs) &&
                <div 
                    className = ' detail_specs-button ' 
                    onClick = { () => setMoreSpecs(true) }
                >
                    <p className=' mb-0 mt-4 small'>Ver Más Especificaciones</p>
                </div>
            }
            { !!moreSpecs &&
                <div 
                    className = ' detail_specs-button ' 
                    onClick = { () => setMoreSpecs(false) }
                >
                    <p className=' mb-0 mt-4 small'>Cerrar Especificaciones</p>
                </div>
            }
        </div>
    )
}
