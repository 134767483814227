import React from 'react';

import testimonio1 from '../assets/reviews/testimonio1.png';
import testimonio2 from '../assets/reviews/testimonio2.png';
import testimonio3 from '../assets/reviews/testimonio3.png';
import { ReviewCard } from '../components/Reviews/ReviewCard';
import { ReviewsFAQ } from '../components/Reviews/ReviewsFAQ';
import { ReviewsGallery } from '../components/Reviews/ReviewsGallery';
import { YoutubeCarousel } from '../components/Reviews/YoutubeCarousel';

export const ReviewsPage = () => {
    return (
        <div>
            <div className = ' testimonials_header '>
                <h1>EXPERIENCIA MOTOELECTRICS</h1>

                <div className = 'testimonials_header_links'>
                    <a href="#testimonios">Testimonios</a>
                    <a className = ' mx-4 ' href="#videos">Videos</a>
                    <a href="#preguntas">Preguntas Frecuentes</a>
                </div>
            </div>

            <div className = 'py-5 p-3 p-md-5' id = 'testimonios'>
                <h3 className = 'my-3'>TESTIMONIOS</h3>
                <div className="row row-cols-1 row-cols-md-4 g-4">
                    <ReviewCard 
                        profilePhoto = { testimonio1 }
                        username = 'Zoe Cervantes'
                        review = 'Excelente Experiencia, muy recomendable.'
                    />
                    <ReviewCard 
                        profilePhoto = { testimonio2 }
                        username = 'Geovany Salazar'
                        review = 'Está muy perra... sí vale la pena comprarla.'
                    />
                    <ReviewCard 
                        profilePhoto = { testimonio3 }
                        username = 'Javier Amaro'
                        review = 'Excelente para los rondines de vigilancia... muchas gracias.'
                    />
                    <ReviewCard 
                        username = 'Martin Ochoa'
                        review = 'La moto responde muy bien, me gusta que se bloquea el acelerador al poner un freno, tiene buena suspensión y el tamaño y diseño muy agradable.'
                    />
                </div>
            </div>

            <div className = ' py-4 '>
                <ReviewsGallery />
            </div>

            <div id = "videos">
                <YoutubeCarousel />
            </div>

            <div id = "preguntas">
                <ReviewsFAQ />
            </div>

        </div>
    )
}
