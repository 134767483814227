import React from 'react'

export const SectionTitle = ({ title, subtitle, id }) => {
    return (
        <div className = ' title_container py-3 ' id = { id }>
            <p className = ' title_subtitle '>{ subtitle }</p>
            <p className = ' title_title '>{ title }</p>
        </div>
    )
}
