import React from 'react'
import { PrimaryButton } from '../components/UI/PrimaryButton'
import { WhiteButton } from '../components/UI/WhiteButton'
import logo from '../assets/logo.png';

export const PageNotFound = () => {
    return (
        <div className = " container py-5 my-4 " >
            <div className = " row d-flex align-items-center p-4 header ">
                <div className = " col-xs-12 col-md-6 text-center text-lg-start "> 
                    <h1>Página no encontrada</h1>
                    <p className = ' mt-4 '>
                        No hay nada por aquí, si te interesa adquirir un producto entra a nuestra tienda o a nuestro inicio para ver los modelos que tenemos.
                    </p>
                    <div className = ' mt-5 mt-md-3 d-flex justify-content-center justify-content-md-start '>
                            <PrimaryButton link = '/' text = 'MODELOS' />
                            <div className = ' mx-2 '/>
                            <WhiteButton link = 'https://motoelectrics.com/tienda/' text = 'TIENDA' external = { true } />
                        </div>
                </div>
                <div className = " col-xs-12 col-md-6 d-flex justify-content-center order-first order-md-last ">
                    <img
                        className = " logo mb-5 mb-md-0 "
                        src = { logo }
                    />
                </div>
            </div>
        </div>
    )
}
