import React from 'react';

import background from './../../assets/home/background-discover.jpeg'

export const HomeElectricMobility = () => {
    return (
        <div 
            className = ' home_mobility d-flex flex-column justify-content-center '
            style = {{
                background: `url(${ background })`,
                backgroundPosition: 'center',
                backgroundSize: 'contain',
            }}
        >

            <p className = ' display-4 '>
                DESCUBRE LA <br />MOVILIDAD ELÉCTRICA
            </p>
            <div className = ' row pt-5 '>
                <div className = ' col-6 col-md-4 '>
                    <p className = ''>
                        <span className = ' display-1 w-100 '>CERO</span><br /> EMISIONES
                    </p>
                </div>
                <div className = ' col-6 col-md-4 '>
                    <p className = ''>
                        <span className = ' display-1 w-100 '>CERO</span><br /> MANTENIMIENTO
                    </p>
                </div>
                <div className = ' col-12 col-md-4 pt-2 pt-md-0 '>
                    <p className = 'd-flex flex-column '>
                        ENTRE <span className = ' display-1 w-100 '>$3 Y $6</span><br /> POR CARGA*
                    </p>
                </div>
            </div>

        </div>
    );
}
